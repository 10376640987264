import React from 'react';
import { IPressArticle } from 'types/press';
import Button from '../../components/ui/Button';

interface Props {
	items: IPressArticle[];
}

function PressList({ items }: Props) {

	return (
		<>
			<div className="schedule__articles">
				<div className="inner">
					{items && items.map((x, i) => {
						return (
							<article key={x.id} className="performance">
								<div className="performance__inner">
									<h3 className="performance__title">{x.title}</h3>
									<p className="performance__date">{x.date}</p>
									{x.url && (
										<div className="performance__button-wrapper">
											<Button opensNewTab className="button--white" url={x.url}>Read article</Button>
										</div>
									)}
								</div>
							</article>
						);
					})}
				</div>
			</div>
		</>
	);
}

export default PressList;
