import React from 'react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import IconButton from '../ui/IconButton';
import Anchor from '../ui/Anchor';

function FooterPage() {

	return (
		<section className="page-footer" data-scroll-section data-bg-colour="brand-one">
			<div className="container">
				<h3>Enquiries</h3>
				<p>For booking, video samples, and other inquiries, <Anchor className="button button--white page-footer__button" url="/" anchorState={{ anchorTo: '#contact' }}>contact Harrison Parrott Management</Anchor></p>
				<div className="page-footer__social">
					<p>Follow</p>
					<ul>
						<li>
							<IconButton
								className="icon-button--sm"
								url="https://www.facebook.com/roderickcoxconductor/"
								opensNewTab
							>
								<FaFacebookF />
							</IconButton>
						</li>
						<li>
							<IconButton
								className="icon-button--sm"
								url="https://www.instagram.com/roderickcoxconductor/"
								opensNewTab
							>
								<FaInstagram />
							</IconButton>
						</li>
					</ul>
				</div>
			</div>
		</section>
	);
}

export default FooterPage;
