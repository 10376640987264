import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import moment from 'moment';
import FooterPage from '../layout/FooterPage';
import PressList from '../template-parts/PressList';
import { IPressArticle } from 'types/press';

interface Props {
	bgImgSrc: string;
	transitionStatus: any;
}

function Press({ bgImgSrc, transitionStatus }: Props) {
	const { scroll } = useLocomotiveScroll();
	const [items, setItems] = useState<IPressArticle[]>([]);

	const data = useStaticQuery(graphql`
        {
            allWordpressWpPress {
                edges {
                    node {
                        id
                        title
                        slug
                        acf {
                            date_published
							url
                        }
                    }
                }
            }
        }
    `);

	useEffect(() => {
		if (scroll === null) {
			return;
		}

		setTimeout(() => {
			scroll.destroy();
			scroll.init();
		}, 1000);
	}, [scroll]);

	useEffect(() => {
		const sortedData = data.allWordpressWpPress.edges.sort((a: any, b: any) => {
			return (new Date(a.node.acf.date_published) as any) + (new Date(b.node.acf.date_published) as any);
		});

		setItems(
			sortedData.map((x: any) => {
				const { node } = x;
				return (
					{
						id: node.id,
						title: node.title,
						url: `${node.acf.url}`,
						date: moment(node.acf.date_published).format('MMMM YYYY'),
					}
				);
			}
			));
	}, [data]);
	
	return (
		<>
			<section id="press-page" className="schedule schedule--page press-page section--fluid" data-scroll-section data-bg-colour="black">
				<div className="section-title" data-scroll data-scroll-speed="-1" data-scroll-direction="vertical">
					<h2 className={`animate ${transitionStatus || ''}`}>Press</h2>
				</div>
				<div className="schedule__bg-wrapper" id="bg">
					<div
						className="schedule__bg"
						style={{ backgroundImage: `url('${bgImgSrc}')` }}
						data-scroll
						data-scroll-sticky
						data-scroll-target="#bg"
						data-scroll-speed="1"
					/>
				</div>
				<div className="container container--fluid">
					<div className={`animate schedule__list${transitionStatus ? ` schedule__list--${transitionStatus}` : ''}`} id="list">
						<PressList items={items} />
					</div>
				</div>
			</section>
			<FooterPage />
		</>
	);
}

export default Press;
