import React, { useRef, useEffect } from 'react';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll';
import Seo from '../components/utils/Seo';
import Header from '../components/layout/Header';
import Cursor from '../components/utils/Cursor';
import Nav from '../components/modules/Nav';
import Press from '../components/pages/Press';
import Loader from '../components/modules/Loader';

function PressPageTemplate({ pageContext, transitionStatus }: any) {
	const containerRef = useRef(null);

	useEffect(() => {
		document.body.classList.remove('bg-white', 'bg-black', 'bg-brand-one', 'bg-home');
	}, []);

	return (
		<>
			<Seo title="Press" />
			<Header />
			<LocomotiveScrollProvider
				options={
					{
						smooth: true,
						direction: 'vertical',
					}
				}
				watch={
					[]
				}
				containerRef={containerRef}
			>
				<Nav />
				<Cursor />
				<main data-scroll-container ref={containerRef}>
					<Press
						bgImgSrc={pageContext.acf.press_banner_image.localFile.childImageSharp.fluid.src}
						transitionStatus={transitionStatus}
					/>
				</main>
			</LocomotiveScrollProvider>
			<Loader transitionStatus={transitionStatus} />
		</>
	);
}

export default PressPageTemplate;
